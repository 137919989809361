import styled from 'styled-components'

export const Container = styled.div``
export const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  .ant-form-item {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
  }
`
