import { useLayoutEffect } from 'react'

export const useDocumentTitle = (pageTitle: string | undefined) => {
  const defaultTitle = 'Acer - SCD'

  const title = pageTitle ? `${pageTitle} | Acer - SCD` : defaultTitle

  useLayoutEffect(() => {
    document.title = `${title}`
  }, [title])
}
