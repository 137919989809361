import { useEffect } from 'react'

import { useRolesStore } from '@/app'

export const useAuth = () => {
  const userRoles = useRolesStore((state) => state.userRoles)
  const userAllowedModules = useRolesStore((state) => state.allowedModules)
  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const getUserRoles = useRolesStore((state) => state.getUserRoles)
  const getPagePermissions = useRolesStore((state) => state.getPagePermissions)

  useEffect(() => {
    if (!userRoles) {
      getUserRoles()
    }
  }, [getUserRoles, userRoles])

  function formatPagePermissions(permissions: string[] | undefined) {
    let isAdmin = false
    let canRead = false // G
    let canCreate = false // P
    let canUpdate = false // U
    let canDelete = false // D

    if (permissions && permissions.length > 0) {
      isAdmin = isSystemAdmin || permissions.includes('*')

      canRead = isSystemAdmin || isAdmin || permissions.includes('g')
      canCreate = isSystemAdmin || isAdmin || permissions.includes('p')
      canUpdate = isSystemAdmin || isAdmin || permissions.includes('u')
      canDelete = isSystemAdmin || isAdmin || permissions.includes('d')
    }

    return { isAdmin, canRead, canCreate, canUpdate, canDelete }
  }

  return {
    userRoles,
    userAllowedModules,
    getPagePermissions,
    formatPagePermissions,
  }
}
