import { Button, Drawer, Layout } from 'antd'
import styled from 'styled-components'

export const Container = styled(Layout.Sider)`
  max-width: 50px !important;
  width: 100% !important;
  min-width: fit-content !important;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.white};

  padding: 8px 0;

  .ant-layout-sider-children {
    width: 100%;
  }

  button.ant-btn {
    width: 100%;
  }
`

export const SidebarTrigger = styled(Button)`
  .anticon {
    color: ${({ theme }) => theme.base.colorPrimary};
    font-size: 20px;

    :hover {
      color: ${({ theme }) => theme.base.colorPrimary};
    }
  }
`

export const FilterDrawer = styled(Drawer)`
  .ant-drawer-close {
    position: absolute;
    top: 25px;
    right: 10px;
    color: ${(props) => props.theme.base.colorPrimary};
  }

  .ant-drawer-title {
    font-size: 20px;
    font-weight: 700;
    color: ${(props) => props.theme.customTokens.text.darkGray};
  }
`
