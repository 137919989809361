import { BrowserRouter, Route, Routes as ReactRoutes, Navigate } from 'react-router-dom'

import { PrivateRoute } from './PrivateRoute'
import {
  RedirectLoginPage,
  HomePage,
} from '@/pages'

export const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        {/* Redirect Login */}
        <Route path="/redirect-login" >
          <Route index element={<RedirectLoginPage />} />
        </Route>

        {/* Private Routes */}
        <Route path="/" element={<PrivateRoute allowedRoles={['*']} />}>
          <Route index element={<Navigate to="/home" replace={false} />} />
          <Route path="home" element={<HomePage />} />
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  )
}
