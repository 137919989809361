import {
  CopyOutlined,
} from '@ant-design/icons'
import {
  Button,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import { toast } from 'sonner'
import dayjs from 'dayjs'

import { BaseTable } from '@/components'
import { Reports } from '@/features'
import { antdTheme, styledTheme } from '@/app/styles'
import * as S from './styles'

type ViewComponentTableProps = {
  data: Reports[]
  isLoading?: boolean
}

export const HomeTable = ({ data, isLoading = false }: ViewComponentTableProps) => {
  const columns: ColumnsType<Reports> = [
    {
      title: 'Modelo',
      key: 'model',
      render: (_, item) => {
        return (
          <div>{item.model}</div>
        )
      },
    },
    {
      title: 'Serial',
      key: 'serial_number',
      render: (_, item) => {
        return (
          <div>{item.serial_number}</div>
        )
      },
    },
    {
      title: 'Módulo',
      key: 'module',
      render: (_, item) => {
        return (
          <div>{item.module}</div>
        )
      },
    },
    {
      title: 'Data Máquina',
      key: 'machine_date',
      render: (_, item) => {
        return (
          <div>{item.machine_date ? dayjs(item.machine_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
        )
      },
    },
    {
      title: 'Sinc. Manual',
      key: 'manual_sync',
      render: (_, item) => {
        return (
          <div>{item.manual_sync ? "Sim" : "Não"}</div>
        )
      },
    },
    {
      title: 'Data Sinc.',
      key: 'sync_date',
      render: (_, item) => {
        return (
          <div>{item.sync_date ? dayjs(item.sync_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, item) => {
        const color =
          item.status === "Fail"
            ? styledTheme.red
            : item.status === "Success"
              ? antdTheme.token.colorPrimary
              : styledTheme.colors.text;
        return (
          <span style={{ color: color }} key={item.status}>
            {item.status}
          </span>
        );
      },
    },
    {
      title: 'Versão SDC',
      key: 'sdc_version',
      render: (_, item) => {
        return (
          <div>{item.sdc_version || "-"}</div>
        )
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, item) => {
        return (
          <S.ButtonContent>
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault()
                copyRowData(item)
              }}
            >
              <CopyOutlined />
            </Button>
          </S.ButtonContent>
        )
      },
    },
  ]

  function copyRowData(report: Reports) {
    const formattedData = formatRowData(report);
    navigator.clipboard
      .writeText(formattedData)
      .then(() => {
        toast.success('Dados copiados para a área de transferência.');
      })
      .catch((err) => {
        toast.error('Falha ao copiar os dados.');
        console.error('Erro ao copiar para a área de transferência: ', err);
      });
  }

  function formatRowData(report: Reports): string {
    const data = [
      `Modelo: ${report.model}`,
      `Serial: ${report.serial_number}`,
      `Módulo: ${report.module}`,
      `Status: ${report.status}`,
      `Sincronização Manual: ${report.manual_sync}`,
      `Data da Maquina: ${dayjs(report.machine_date).format('DD/MM/YYYY')}`,
      `Hora da Maquina: ${dayjs(report.machine_date).format('HH:mm')}`,
      `Data Sincronização: ${dayjs(report.sync_date).format('DD/MM/YYYY')}`,
      `Hora Sincronização: ${dayjs(report.sync_date).format('HH:mm')}`,
      `Versão SDC: ${report.sdc_version}`,
    ];
    return data.join('\n');
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => `${record.id}`}
      loading={isLoading}
      pagination={false}
    />
  )
}
