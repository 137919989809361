import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface HomeFilters {
  page_size?: number
  page?: number
  serial_number?: string
  start_sync_date?: string
  end_sync_date?: string
  start_machine_date?: string
  end_machine_date?: string
  manual_sync?: boolean
}

interface HomeFilterStore {
  filters: HomeFilters
  resetFilters: () => void
  setFilters: (filters: HomeFilters) => void
}

const homeFilterStore = createWithEqualityFn<HomeFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          page_size: 10,
          page: 1,
          serial_number: undefined,
          start_sync_date: undefined,
          end_sync_date: undefined,
          start_machine_date: undefined,
          end_machine_date: undefined,
          manual_sync: undefined,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-home-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                page_size: 10,
                page: 1,
                serial_number: undefined,
                start_sync_date: undefined,
                end_sync_date: undefined,
                start_machine_date: undefined,
                end_machine_date: undefined,
                manual_sync: undefined,
              },
            },
            false,
            'reset-home-filters',
          )
        },
      }),
      { name: 'home-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'home-filter-store' },
  ),
  shallow,
)

export const resetHomeFilters = homeFilterStore.getState().resetFilters

export { homeFilterStore, homeFilterStore as useHomeFilters }
