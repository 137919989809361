import { Checkbox, DatePicker, Divider, Form, FormInstance, Input } from 'antd'
import * as S from './styles'
import dayjs, { Dayjs } from 'dayjs'
import { toast } from 'sonner';
import { HomeFilters } from '@/app';


interface FilterProps {
  form: FormInstance<HomeFilters>
}

export function HomeFilterForm({ form }: FilterProps) {

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day');
  };

  const validateSyncDate = (_: any, endDate: dayjs.Dayjs) => {
    const startDate = form.getFieldValue('start_sync_date')
    if (startDate && endDate && endDate.isBefore(startDate)) {
      toast.error('A data final não pode ser menor que a data inicial')
      return Promise.reject(new Error('A data final não pode ser menor que a data inicial'))
    }
    return Promise.resolve()
  }
  const validateMachiningDate = (_: any, endDate: dayjs.Dayjs) => {
    const startDate = form.getFieldValue('start_machine_date')
    if (startDate && endDate && endDate.isBefore(startDate)) {
      toast.error('A data final não pode ser menor que a data inicial')
      return Promise.reject(new Error('A data final não pode ser menor que a data inicial'))
    }
    return Promise.resolve()
  }

  return (
    <S.Container>
      <Form.Item
        name={'serial_number'}
        label={'Número de série'}
      >
        <Input />
      </Form.Item>
      <Divider />

      <S.DateBox>
        <label>Intervalo de Sincronização</label>
        <S.Row>
          <Form.Item
            name="start_sync_date"
            label={'Data Inicial'}
          >
            <DatePicker
              placeholder="DD/MM/AAAA"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            name="end_sync_date"
            label={'Data Final'}
            rules={[{ validator: validateSyncDate }]}
          >
            <DatePicker
              placeholder="DD/MM/AAAA"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </S.Row>
      </S.DateBox>
      <Divider />
      <S.DateBox>
        <label>Intervalo Data Maquina</label>
        <S.Row>
          <Form.Item
            name="start_machine_date"
            label={'Data Inicial'}
          >
            <DatePicker
              placeholder="DD/MM/AAAA"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            name="end_machine_date"
            label={'Data Final'}
            rules={[{ validator: validateMachiningDate }]}
          >
            <DatePicker
              placeholder="DD/MM/AAAA"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </S.Row>
      </S.DateBox>
      <Divider />

      <Form.Item
        name={'manual_sync'}
        valuePropName='checked'
      >
        <Checkbox > Sincronização Manual</Checkbox>
      </Form.Item>
    </S.Container >
  )
}



