import { Button, Form } from "antd";
import { shallow } from "zustand/shallow";

import { getActiveFiltersList, removeActiveFilter, useToggle } from "@/common";
import { ActiveFiltersList, CustomPagination, FilterSidebar } from "@/components";
import { PageLayout } from "@/layouts";
import { HomeFilters, useHomeFilters } from "@/app";
import { HomeFilterForm, HomeTable, reportsQueries } from "@/features";

import * as S from './styles'
import { useQuery } from "@tanstack/react-query";

export function HomePage() {
  const [form] = Form.useForm<HomeFilters>()
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  //HANDLE STORES
  const { homeFilters, setFilters, resetHomeFilters } = useHomeFilters(
    (state) => ({
      homeFilters: state.filters,
      setFilters: state.setFilters,
      resetHomeFilters: state.resetFilters,
    }),
    shallow,
  )

  //HANDLE QUERIES
  const {
    data: reports,
    isLoading: isReportsLoading,
  } = useQuery(reportsQueries.list(homeFilters))

  //REPORTS FILTER
  function handleFilter() {
    let values = form.getFieldsValue()

    values = Object.fromEntries(Object.entries(values).map(([key, value]) => [
      key,
      value === '' || value === false ? undefined : value,
    ]))

    setFilters({
      ...values,
      page: 1,
      page_size: 10
    })
  }

  function submitFilter() {
    form.submit()
    toggleFilterDrawer()
  }

  function handleClearFilters() {
    form.resetFields()

    resetHomeFilters()
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, page_size: pageSize })
  }

  function handleRemoveFilter(filterKey: keyof HomeFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter<HomeFilters>({
      activeFilters: homeFilters,
      filterKey,
      filterValue,
    })

    form.setFieldsValue(updatedFilters)

    setFilters({ ...updatedFilters })
  }

  //HANDLE SIDE BAR
  const sidebar = () => {
    return (
      <FilterSidebar
        isFilterDrawerOpen={isFilterDrawerOpen}
        onClose={toggleFilterDrawer}
        filterFooter={
          <S.FooterSidebar>
            <S.CleanButton onClick={() => handleClearFilters()}>
              Limpar
            </S.CleanButton>
            <Button type="primary" htmlType="submit" onClick={() => submitFilter()}>
              Filtrar
            </Button>
          </S.FooterSidebar>
        }
      >
        <Form
          form={form}
          requiredMark
          layout="vertical"
          name={'filter-component'}
          onFinish={() => handleFilter()}
        >
          <HomeFilterForm form={form} />
        </Form>
      </FilterSidebar>
    )
  }

  return (
    <PageLayout
      title="Home"
      sidebar={sidebar()}
    >
      <S.Body>
        <S.Header>
          <S.TitleContainer>
            <S.Title level={4}>Relatórios</S.Title>
          </S.TitleContainer>
        </S.Header>
        <ActiveFiltersList
          filtersList={getActiveFiltersList(homeFilters)}
          removeFilter={handleRemoveFilter}
          cleanFiltersList={handleClearFilters}
        />
        <HomeTable
          data={reports?.reports || []}
          isLoading={isReportsLoading}
        />
        <CustomPagination
          scrollToTop
          page={homeFilters.page || 1}
          pageSize={homeFilters.page_size || 10}
          totalItems={reports?.total || 0}
          totalPages={reports?.total_pages || 1}
          isLoading={isReportsLoading}
          changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
        />
      </S.Body>
    </PageLayout>
  )
}
