import { Fragment, ReactNode } from 'react'
import { Divider } from 'antd'

import { filtersRemap, formatDate } from '@/common'
import { FilterTag } from '@/components'
import * as S from './styles'

interface ActiveFiltersListProps<T> {
  filtersList: [string, any][]
  removeFilter: (filterKey: keyof T, filterValue?: string) => void
  cleanFiltersList: () => void
}

interface FormatFilterProps {
  filterValue: string | boolean
  filterKey?: string
}

export function ActiveFiltersList<T extends Record<string, any>>({
  filtersList,
  removeFilter,
  cleanFiltersList,
}: ActiveFiltersListProps<T>) {
  const excludedFilterKeys = ['page', 'page_size']

  function formatFilters({ filterValue, filterKey }: FormatFilterProps): string {
    if (filterKey) {
      const formatter = filtersRemap.get(filterKey)

      if (formatter) {
        if (typeof formatter === 'function') {
          return formatter(filterValue)
        }

        return formatter as string
      }
    }

    const formattedFilter = filtersRemap.get(filterValue as string)

    if (formattedFilter) {
      return formattedFilter as string
    }

    return filterValue as string
  }

  return (
    <S.ActiveFiltersContainer>
      {(() => {
        let filterTagsRendered = 0

        const totalValidTags = filtersList.reduce((count, [filterKey, filterValue]) => {
          if (excludedFilterKeys.includes(filterKey) || filterValue === undefined || (filterKey !== 'is_complete' && !filterValue)) {
            return count
          }

          return count + (Array.isArray(filterValue) ? filterValue.length : 1)
        }, 0)
        return filtersList.map(([filterKey, filterValue], i) => {
          if (excludedFilterKeys.includes(filterKey) || filterValue === undefined || (filterKey === 'without_orders' && !filterValue)) {
            return null
          }

          const isArray = Array.isArray(filterValue)
          const isDate = filterKey === 'start_sync_date' ||
            filterKey === 'end_sync_date' ||
            filterKey === 'end_machine_date' ||
            filterKey === 'start_machine_date'

          let tags: ReactNode[] = []
          if (isDate) {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {filterKey === 'start_sync_date' ? 'Data inicial de sincronização' : null}
                {filterKey === 'end_sync_date' ? 'Data final de sincronização' : null}
                {filterKey === 'start_machine_date' ? 'Data máquina inicial' : null}
                {filterKey === 'end_machine_date' ? 'Data máquina final' : null}
                <Divider type='vertical' style={{ margin: 0 }} />
                <S.TagBlueText >
                  {`${formatDate({
                    date: filterValue,
                    dateFormat: 'DD/MM/YYYY',
                  })}`}
                </S.TagBlueText>
              </FilterTag>,
            )
          } else if (typeof filterValue === 'boolean') {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {formatFilters({ filterKey, filterValue })}
              </FilterTag>,
            )
          } else if (isArray) {
            tags = [
              ...tags,
              ...filterValue.map((filter, i) => (
                <FilterTag
                  closable
                  handleRemoveTag={() => removeFilter(filterKey, filter)}
                  key={`${filterKey}-${i}`}
                >
                  {formatFilters({ filterValue: filter })}
                </FilterTag>
              )),
            ]
          } else {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {`${filtersRemap.get(filterKey)}`}
                <Divider type='vertical' style={{ margin: 0 }} />
                <S.TagBlueText >
                  {formatFilters({ filterValue: filterValue })}
                </S.TagBlueText>
              </FilterTag>,
            )
          }

          filterTagsRendered += tags.length

          if (filterTagsRendered === totalValidTags && totalValidTags > 1) {
            tags.push(
              <S.TagClearAll
                key={'clear'}
                onClick={cleanFiltersList}
                style={{ cursor: 'pointer' }}
              >
                Limpar filtros
              </S.TagClearAll>,
            )
          }

          return <Fragment key={i}>{tags}</Fragment>
        })
      })()}
    </S.ActiveFiltersContainer>
  )
}


