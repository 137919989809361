import { keepPreviousData, queryOptions } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { api } from '@/services'
import { generateSearchParams } from '@/common'
import { Reports } from '@/features'
import type { HomeFilters } from '@/app'

export const reportsQueries = {
  all: () => ['reports'],
  lists: () => [...reportsQueries.all(), 'list'],
  list: (filters: HomeFilters = {}) =>
    queryOptions({
      queryKey: [...reportsQueries.lists(), filters],
      queryFn: () => fetchReportsList(filters),
      placeholderData: keepPreviousData,
    }),
}

async function fetchReportsList(filters: HomeFilters) {
  const { page, page_size, ...activeFilters } = filters
  if (activeFilters.end_sync_date) {
    activeFilters.end_sync_date =
      dayjs(activeFilters.end_sync_date).format('YYYY-MM-DD') + ' 23:59:59'
  }
  if (activeFilters.start_sync_date) {
    activeFilters.start_sync_date =
      dayjs(activeFilters.start_sync_date).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if (activeFilters.end_machine_date) {
    activeFilters.end_machine_date =
      dayjs(activeFilters.end_machine_date).format('YYYY-MM-DD') + ' 23:59:59'
  }
  if (activeFilters.start_machine_date) {
    activeFilters.start_machine_date =
      dayjs(activeFilters.start_machine_date).format('YYYY-MM-DD') + ' 00:00:00'
  }

  const params = generateSearchParams(activeFilters)

  const response = await api.get<Reports[]>(
    `/reports?page_size=${page_size}&page=${page}&${params}`,
  )

  const list = {
    reports: response.data,
    page: response.headers['x-page'],
    page_size: response.headers['x-page-size'],
    total: response.headers['x-total-count'],
    total_pages: response.headers['x-total-pages'],
  }
  return list
}
