import axios from 'axios'

import { authStore } from '@/app'
import rolesStore from '@/app/stores/roles'
import { ApiError, clearAllCaches } from '@/common'
import { RefreshLoginResponse } from '@/features/auth'
import { jwtDecode } from 'jwt-decode'

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
})

api.defaults.headers.common.Authorization = `Bearer ${authStore.getState().id_token}`

let isRefreshing = false
let failedRequestsQueue: any[] = []

api.interceptors.response.use(
  (response) => response,
  (error: ApiError) => {
    if (
      error.response?.status === 401 &&
      error.response?.data.message === 'The incoming token has expired'
    ) {
      const idToken = authStore.getState().id_token
      const refreshToken = authStore.getState().refresh_token

      const originalConfig = error.config
      if (!isRefreshing) {
        isRefreshing = true

        const decodedIdToken = jwtDecode<{ 'cognito:username': string }>(idToken as string)
        const userId = decodedIdToken['cognito:username']

        api
          .post('/auth/login', {
            refresh_token: refreshToken as string,
            user_sso_id: userId,
          })
          .then(({ data }) => {
            const { access_token, id_token, refresh_token, permissions } =
              data as RefreshLoginResponse

            authStore.setState({
              access_token: access_token,
              id_token: id_token,
              refresh_token: refresh_token,
              isUserAuthenticated: true,
            })

            api.defaults.headers.common.Authorization = `Bearer ${id_token}`

            rolesStore.getState().getUserRoles(id_token)
            rolesStore.setState({ userRoles: permissions })

            failedRequestsQueue.forEach((req) => req.onSuccess(id_token))
            failedRequestsQueue = []
          })
          .catch((err) => {
            failedRequestsQueue.forEach((req) => req.onFailure(err))
            failedRequestsQueue = []
            clearAllCaches()
          })
          .finally(() => {
            isRefreshing = false
          })
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalConfig!.headers!.Authorization = `Bearer ${token}`

            resolve(api(originalConfig!))
          },
          onFailure: (error: ApiError) => reject(error),
        })
      })
    }

    return Promise.reject(error)
  },
)
