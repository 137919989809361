import { jwtDecode } from 'jwt-decode'
import pako from 'pako'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import authStore from './auth'

type GetPagePermissions = {
  moduleKey: string
  roleKey: string
}

interface RolesStoreState {
  userRoles: any | null
  allowedModules: string[] | null
  isSystemAdmin: boolean
  getUserRoles: (idToken?: string) => void
  getPagePermissions: ({ moduleKey, roleKey }: GetPagePermissions) => string[] | undefined
  clearRolesStore: () => void
}

const rolesStore = create<RolesStoreState>()(
  devtools(
    (set, get) => {
      return {
        userRoles: null,
        allowedModules: null,
        isSystemAdmin: false,

        getUserRoles: (idToken) => {
          const token = idToken || authStore.getState().id_token

          if (token) {
            try {
              const { access_data: accessData } = jwtDecode<{ access_data: string }>(token)

              const binary = atob(accessData)
              const charData = binary.split('').map((char) => char.charCodeAt(0))
              const binData = new Uint8Array(charData)

              const decompressedRoles = pako.inflate(binData, { to: 'string' })
              const decodedRoles = JSON.parse(decompressedRoles)

              const roles = decodedRoles?.roles?.cadastro_de_produto as Record<
                string,
                string | string[]
              >

              if (roles) {
                set({ allowedModules: Object.keys(roles) })

                set({ userRoles: roles })
              }
            } catch (err) {
              console.error('Failed getting user roles', err)
              set({ userRoles: null, allowedModules: null })
            }
          }
        },

        getPagePermissions: ({ moduleKey, roleKey }) => {
          const userRoles = get().userRoles

          if (!userRoles) return undefined

          try {
            const isSystemAdmin = userRoles?.['*']?.includes('*')

            if (isSystemAdmin) {
              set({ isSystemAdmin: true })
              return ['*']
            }

            const module = userRoles?.[moduleKey] as string[]

            if (!module) {
              console.log('No module found')
              return undefined
            }

            const isModuleAdmin = module.includes('*') || module.includes('*:*')

            if (isModuleAdmin) {
              return ['*']
            }

            const modulePerms = module?.find((role) => role.startsWith(`${roleKey}:`))

            if (!modulePerms) {
              console.log('No module perms found')
              return undefined
            }

            const pagePerms = modulePerms
              .split(':')[1]
              .split(' ')
              .filter((char: string) => char.trim() !== '')
              .flat()

            return pagePerms
          } catch (err) {
            console.error('Error getting page permissions', err)
          }
        },

        clearRolesStore: () => {
          set({ userRoles: null, allowedModules: null, isSystemAdmin: false })
        },
      }
    },
    { name: 'user-roles-store' },
  ),
)

export const clearRolesStore = rolesStore.getState().clearRolesStore

export { rolesStore as useRolesStore }

export default rolesStore
