import { CameraOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

interface TagProps {
  $type: string
}

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
`

export const SwitchLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #80c343;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: left;
  height: 40px;
`

export const DashboardCategoryDate = styled.span`
  font-weight: 700;
  font-size: ${rem(14)};
  color: #00000080;
`
export const ConfirmDeletionModalText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`
export const PartNumberText = styled.span`
  display: inline-flex;
  gap: 6px;
  font-weight: 700;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  .ant-switch {
    background-color: #fff;
    border: 1px solid #80c343;
  }
  .ant-switch-checked {
    background-color: #80c343;
    border: 1px solid #80c343;
  }
  .ant-switch:hover {
    background-color: #fff;
    border: 1px solid #80c343;
  }
  .ant-switch-handle {
    border: 1px solid #80c343;
    border-radius: 50%;
  }
`

export const NcmContainer = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  height: 24px;
  width: 24px;
`

export const Orders = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: left;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
`

export const StatusTag = styled(Tag)<TagProps>`
  display: flex;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.matteBlue};
  border-radius: 4px;

  border: 1px solid
    ${({ $type }) =>
      $type === 'PENDING'
        ? '#E864524D'
        : $type === 'AI'
          ? '#9BC3FD'
          : $type === 'FINISHED'
            ? '#999999'
            : '#FF8B274D'};
  background-color: ${({ $type }) =>
    $type === 'PENDING'
      ? '#FFF4EA'
      : $type === 'AI'
        ? '#E6EEFA'
        : $type === 'FINISHED'
          ? '#F8F8F8'
          : '#FFF4EA'};
  color: ${({ $type }) =>
    $type === 'PENDING'
      ? '#E86452'
      : $type === 'AI'
        ? '#4A91FD'
        : $type === 'FINISHED'
          ? '#999999'
          : '#FF8B27'};
  font-size: 12px;
`

export const DeactivatedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.lightGray};
  pointer-events: none;
  cursor: default;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 5px;
`

export const AlertButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 4px 6px;

  height: ${rem(21)};
  width: ${rem(116)};

  font-size: 12px;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(62)};
  width: ${rem(62)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.darkGreen};
`
