import styled from 'styled-components'
import { Button as AntdButton, Typography } from 'antd'
import { rem } from 'polished'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CleanButton = styled(AntdButton)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const FooterSidebar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const Title = styled(Typography.Title)`
  font-weight: 400;
  margin-bottom: 0 !important;
  font-size: ${rem(20)};
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-weight: 400;
    font-size: ${rem(24)};
    color: ${({ theme }) => theme.colors.bgColor};
    margin: 0;
  }
`
