import { ReactNode } from 'react'
import * as S from './styles'

interface FilterTagProps {
  children: ReactNode
  closable?: boolean
  handleRemoveTag?: () => void
}

export const FilterTag = ({ children, closable, handleRemoveTag }: FilterTagProps) => {
  return (
    <S.Tag>
      {children}
      {closable && handleRemoveTag && <S.RemoveTagButton onClick={handleRemoveTag} />}
    </S.Tag>
  )
}
