import { Button, Layout, Drawer as AntdDrawer, Typography } from 'antd'
import { rgba } from 'polished'
import styled from 'styled-components'

export const Container = styled(Layout.Header)`
  height: 50px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.customTokens.header.bgColor};
  border-bottom: 3px solid ${({ theme }) => theme.colors.brand};

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    cursor: pointer;
  }
`

export const LogoContainer = styled.div`
  padding: 0 8px;
  display: flex;
  cursor: pointer;
  .ant-typography {
    line-height: 1.1;
  }
`

export const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
`

export const LogoText = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 700;
  color: #94d956;
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const MenuButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;

  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  transition: all 0.2s ease;

  &:hover,
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const MenuDrawer = styled(AntdDrawer)`
  .ant-drawer-title {
    color: ${({ theme }) => theme.colors.text};

    :not(:disabled):hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.darkGreen};

    :not(:disabled):hover {
      color: ${({ theme }) => rgba(`${theme.colors.brand}`, 0.8)};
    }
  }
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: end;

    .ant-btn-default {
      :not(:disabled):hover {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }
`

export const LogOutButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const MenuContent = styled.div`
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ant-menu-item {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.brand};

    &:hover {
      color: ${({ theme }) => theme.colors.white} !important;
      background: ${({ theme }) => theme.colors.brand} !important;
    }

    &.ant-menu-item.ant-menu-item-selected {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.brand};

      font-weight: 700;
    }

    span.ant-menu-title-content {
      transition: 0s;
    }
  }
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 18px;

  /* .ant-btn {
    padding: 0;
    background: none;
    border-color: #434343;

    :hover {
      border-color: #434343 !important;
    }
  } */
`

export const UserButton = styled(Button)`
  padding: 0;
  background: none;
  border-color: #434343;

  &:disabled {
    cursor: default;
  }

  /* :hover {
    color: ${({ theme }) => theme.colors.brand};
    transition: ease-in-out 0.3s;
  } */
`

export const UserArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 12px;

  border: none;
  /* line-height: initial; */

  color: ${({ theme }) => theme.colors.white};
`

export const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;
  font-size: 14px;

  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  transition: all 0.2s ease;

  &:hover,
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-badge {
    transition: all 0.2s ease;
    color: ${({ theme }) => theme.colors.white};

    &:hover,
    .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`
